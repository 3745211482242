<template>
  <div id="main-wrapper" class="inner-pages forex-page mt4-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li><router-link to="/mt4">{{ $t("platforms.submenu.mt4.title") }}</router-link></li>
            <!-- <li><router-link to="/mt5">{{ $t("platforms.submenu.mt5.title") }}</router-link></li> -->
            <!-- <li class="active">
              <router-link to="/act-trader">{{ $t("acttrader") }}</router-link>
            </li> -->
            <li><router-link to="/zulu">{{ $t("tools.submenu.zulu.title") }}</router-link></li>
          </ul>
        </div>
      </div>

      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>
          
          <b-button v-b-tooltip.hover title="Coming Soon...">
						{{ $t("acttraderpage.title") }}*
					</b-button>
        </h2>
        <div class="img-holder">
          <img src="assets/images/act-img1.webp"  alt="AAAFx" title="AAAFx" class="fluid-img" width="728" height="395" />
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h6>{{ $t("acttraderpage.para1") }}</h6>
          <br />
          <p>{{ $t("acttraderpage.para2") }}</p>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper2">
        <div class="content-sec act-desk">
          <div class="img-holder wow fadeInLeft">
            <img src="assets/images/act-img2.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="695" height="319" />
          </div>
          <div class="content-holder wow fadeInRight">
            <img src="assets/images/act-ico1.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="298" height="120" />
            <br /><br />
            <p>{{ $t("acttraderpage.para3") }}</p>

            <div class="btn-holder wow fadeIn">
              <a
                :href="static_vars.platformLinks.actTrader.desktop"
                class="btn btn-grey"
                title="ActTrader Desktop"
              >
                <img src="assets/images/desk-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
                {{
                  static_vars.platformLinks.actTrader.desktop ==
                  "javascript:void(0);"
                    ? "Coming Soon"
                    : "Download ActTrader Desktop"
                }}
              </a>
              <a
                :href="static_vars.platformLinks.actTrader.web"
                class="btn btn-grey"
                title="ActTrader Web"
              >
                <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
                {{
                  static_vars.platformLinks.actTrader.web ==
                  "javascript:void(0);"
                    ? "Coming Soon"
                    : "Download ActTrader Web"
                }}
              </a>
            </div>
          </div>
        </div>
        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="desktop ^= true" :class="[{ active: desktop }]">
              {{ $t("acttraderpage.questionnaire.question1.heading") }}
            </h3>
            <div class="content" v-show="desktop">
              <p>
                1. Download the platform from above link. <br />
                2. Run the .exe file <br />
                3. Enter your trading account number and password on your ACT
                platform. <br />
                4. Select the account type : Demo for demo account / Real for
                live account.<br />
                5. Select your preferred language.<br />
                6. Click on “login”<br />
                7. A small box will pop up. Select the time zone.<br />
                8. Again, a small box will pop up. Select the layout of the
                platform beginner / advanced.<br />
              </p>
            </div>
            <h3
              v-on:click="uninstalldesktop ^= true"
              :class="[{ active: uninstalldesktop }]"
            >
              Steps to uninstall ActTrader Desktop
            </h3>
            <div class="content" v-show="uninstalldesktop">
              <p>
                1. Click on Start button in Windows <br />
                2. The ACT application will be included in list of programs.
                <br />
                3. Right click on the application name and select ‘Uninstall’.
              </p>
            </div>
            <h3 v-on:click="web ^= true" :class="[{ active: web }]">
              Steps to log into Web-based Act Trader
            </h3>
            <div class="content" v-show="web">
              <p>
                1. Click on the Web Trader link <a href="#">here</a> <br />
                2. Select "Demo web trader" for demo account or "Live web
                trader" for live account. <br />
                3. A new tab will open.<br />
                4. Fill in the login credentials. You are now logged in. <br />
              </p>
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="content-sec mt4-phone act-phone">
            <div class="content-holder wow fadeInLeft">
              <img src="assets/images/act-ico2.webp" alt="AAAFx" width="240" height="125" class="fluid-img" />
              <br /><br />
              <p>Begin your smooth trading journey with ActTrader:</p>
              <div class="btn-holder wow fadeIn">
                <a
                  :href="static_vars.platformLinks.actTrader.android"
                  class="btn btn-grey"
                  title="ActTrader Android"
                >
                  <img src="assets/images/phone-ico2.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.actTrader.android ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download ActTrader Android"
                  }}
                </a>
                <a
                  :href="static_vars.platformLinks.actTrader.ios"
                  class="btn btn-grey"
                  title="ActTrader iOS"
                >
                  <img src="assets/images/phone-ico1.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.actTrader.ios ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download ActTrader iOS"
                  }}
                </a>
              </div>
            </div>
            <div class="img-holder wow fadeInRight">
              <img src="assets/images/act-img3.webp" alt="AAAFx" title="AAAFx" width="650" height="414" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="android ^= true" :class="[{ active: android }]">
              Steps to download ActTrader Android
            </h3>
            <div class="content" v-show="android">
              <p>
                1. Install the application from the above link. <br />
                2. Click on the installed application on your android device.
                <br />
                3. Press “Agree” on the license agreement. <br />
                4. You need to enter your trading account number on username and
                it’s password. <br />
                5. Select account type :AAAFX Demo for demo and AAAFX Live for
                live. <br />
                6. Click on “login”
              </p>
            </div>
            <h3
              v-on:click="unistallandroid ^= true"
              :class="[{ active: unistallandroid }]"
            >
              Steps to uninstall ActTrader Android
            </h3>
            <div class="content" v-show="unistallandroid">
              <p>
                1. On the Menu, go to Settings > Applications > Manage
                applications <br />
                2. Find ACT listed in there and tap on its name. <br />
                3. Tap 'Uninstall'. <br />
              </p>
            </div>
            <h3 v-on:click="ios ^= true" :class="[{ active: ios }]">
              Steps to download ActTrader iOS
            </h3>
            <div class="content" v-show="ios">
              <p>
                1. Open ActPhone app. <br />
                2. Enter the login credentials shared with you via email. <br />
                3. In Account type, scroll down and select AAAFX Demo for a Demo
                account or AAAFX Live for a Live account. <br />
                4. Select Sign In and wait until your account loads.
              </p>
            </div>
            <h3
              v-on:click="unistallios ^= true"
              :class="[{ active: unistallios }]"
            >
              Steps to uninstall ActTrader iOS
            </h3>
            <div class="content" v-show="unistallios">
              <p>
                1. Long press on the ActTrader application icon until all the
                icons on your screen begin to shake. <br />
                2. Click the 'x' in the upper left-hand corner of the icon for
                the ActTrader application.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h3>
            AAAFx ActTrader promises to allow a user to explore a multitude of
            trading opportunities with tailor-made conditions and the ability to
            overview everything from scratch.
          </h3>
          <br />
          <p>Key features of AAAFx ActTrader platform:</p>
        </div>
        <div class="img-holder t-center">
          <img src="assets/images/act-img4.webp" alt="AAAFx" title="AAAFx" width="1127" height="287" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="content-sec mt4-phone why-mt4 why-mt5">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h5>
                Still hesitant? Have a look at the brief overview of the
                features provided over the ActTrader platform:
              </h5>
            </div>
            <p>In-depth analytical tools:</p>
            <ul class="listing-check">
              <li>Wide variety of already installed indicators</li>
              <li>Multiple drawing tools to use over the charts</li>
              <li>Simplified working with one-click trading option</li>
              <li>
                Set-up key price alerts so that you never miss out on any
                opportunity
              </li>
              <li>
                Export account’s data directly to excel with utmost convenience
              </li>
            </ul>
            <br />
            <p>Personalize your trading window:</p>
            <ul class="listing-check">
              <li>Add/remove additional workspace</li>
              <li>Format the date/time settings</li>
              <li>
                Add colors to your trading terminal and make a fun workspace for
                yourself
              </li>
              <li>Stream platform prices the way you like</li>
            </ul>
            <p>Instruments offered:</p>
            <ul class="listing-check">
              <li>70+ forex currencies</li>
              <li>Popular commodities including gold and oil</li>
              <li>11 global indices- Nasdaq, Dow Jones, etc.</li>
              <li>6 major and most transacted crypto currencies</li>
            </ul>
          </div>
          <div class="img-holder wow fadeInRight">
            <img src="assets/images/act-img5.webp" alt="AAAFx" title="AAAFx" width="575" height="365" class="fluid-img" />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      desktop: false,
      uninstalldesktop: false,
      web: false,
      android: false,
      unistallandroid: false,
      ios: false,
      unistallios: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
